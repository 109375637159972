<template>
  <div class="common-layout">
    <el-scrollbar>
      <el-container>
        <el-header class="header-bg" height="80px">
          <div class="header-line" style="height: 1rem;"></div>
          <el-row>
            <el-col :span="24">
              <img
                style="width: 230px"
                src="../assets/logo.png"
                alt="Element logo"
              />
            </el-col>
          </el-row>
        </el-header>
        <el-main>
          <div class="banner-tp">
            <el-row :align="middle" :justify="center">
              <el-col :span="12"><div class="grid-content ep-bg-purple" />
                <p class="tp-font-p">合规分析工具</p>
                <span class="tp-font-span">专业分析工具，支持一键式地址、交易展示，让调查分析从此零门槛。</span>
              </el-col>
  
  
              <el-col :span="12">
                <div class="grid-content ep-bg-purple-light" />
                <img src="../assets/banner-right.png" style="width: 300px;">
              
              </el-col>
            </el-row>
          </div>
  
          <div class="main-center">
            <el-row :align="middle" :justify="center">
              <el-col :span="24">
                <!-- <SelectMenu /> -->
                 <SelectMenu :category="category" :address="address" />
              </el-col>
            </el-row>
          </div>
  
          <MainTwo />
        </el-main>
      </el-container>
      <el-footer>
        <FooterBox />
      </el-footer>
    </el-scrollbar>
    <el-backtop target=".el-scrollbar__wrap" :visibility-height="100" />

  </div>
</template>
  
  <script>
import SelectMenu from './SelectMenu.vue';
import FooterBox from './FooterBox.vue';
import MainTwo from './MainTwo.vue';
  
import getSearch from './getSearch.vue';
import { useHead } from '@vueuse/head';
  export default {
    setup() {
    useHead({
      title: '深圳市华创技术有限公司-链上分析工具',
      meta: [
        { name: '深圳市华创技术有限公司-链上分析工具', content: '专业分析工具，支持一键式地址、交易展示，让调查分析从此零门槛。' }
      ]
    });
  },
    name: 'App',
    components: {
      SelectMenu,
      FooterBox,
      MainTwo,
      getSearch
    },
    data() {
      return {
        category: this.$route.params.category,
        address: this.$route.params.address,
      };
    },
    
  }
  </script>
  
<style>
.common-layout {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

}
.flex-grow {
  flex-grow: 1;
}

.header-bg {
  background: #000;
}

.el-menu--horizontal.el-menu {
  border-bottom: 0px;
}


.tp-font-p {
  font-size: 3rem;
  text-align: right;
  font-weight: bold;
}

.tp-font-span {
  font-size: 20px;
  text-align: right;
  display: block;
}


.el-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
}

.el-main {
  width: 90%;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  background: #fff;
}

.el-footer {
  --el-footer-padding:0px !important;
  padding: 0px;
}

.main-center {
  margin-top:3rem;
}

@media screen and (max-width:768px){ 
  .el-header {
      width: 100%;
  }

  .el-main {
    width: 100%;
    --el-main-padding: 0 !important;
  }

  .el-col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .tp-font-p {
    text-align: center;
    font-size: 1.8rem;

  }

  .tp-font-span {
      font-size: 15px;
      text-align: left;
      display: block;
      width: 90%;
      margin: 0 auto;
  }

  .main-center {
    margin-top:0;
    width: 95%;
    margin: 0 auto;
  }

  .el-footer {
    --el-footer-padding:0px !important;
    padding: 0px;
  }
}

</style>