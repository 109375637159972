<template>
    <div>
      <h1>Search Details</h1>
      <p>Address: {{ address }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      address: {
        type: String,
        required: true,
      },
    },
    mounted() {
      
    },
  };
  </script>
  