import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/Home.vue';
import SearchDetails from '../components/SearchDetails.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/address/:category/:address',
    name: 'Address',
    component: Home,
    props: true,
  },
  {
    path: '/searchdetails/:address',
    name: 'SearchDetails',
    component: SearchDetails,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
