<template>
  <el-tabs v-model="activeName" @tab-click="handleTabClick">
    <el-tab-pane label="地址分析" name="first">
      <getSearch v-if="activeName === 'first'" :key="getSearchKey" :category="category" :address="address" />
    </el-tab-pane>

    <el-tab-pane class="haslist-box" label="交易分析" name="second">
      <hasList v-if="activeName === 'second'" :key="hasListKey" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import getSearch from '@/components/getSearch.vue';
import hasList from '@/components/hasList.vue';

export default {
  data() {
    return {
      activeName: 'first', // 默认值
      getSearchKey: 0,
      hasListKey: 0
    };
  },
  components: {
    getSearch,
    hasList,
  },
  props: {
    category: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleTabClick(tab) {
      // 保存当前选中的标签页名称到 localStorage
      localStorage.setItem('activeTab', tab.name);

      // 刷新页面并导航到主页
      window.location.href = '/'; // 刷新到主页
    },
  },
  mounted() {
    // 从 URL 参数中获取地址和类别
    const urlParams = new URLSearchParams(window.location.search);
    const addressParam = urlParams.get('address');
    const categoryParam = urlParams.get('category');

    // 从 localStorage 中获取选中的标签页
    const savedTab = localStorage.getItem('activeTab');
    
    // 判断 URL 是否包含 address 和 category 参数
    if (addressParam && categoryParam) {
      // URL 包含参数，设置为用户选择的标签页
      this.activeName = window.location.pathname.includes('second') ? 'second' : 'first';
      // 确保在刷新后，URL 中的参数会被移除或处理
      window.history.replaceState(null, null, '/'); // 清除 URL 参数
    } else if (savedTab) {
      // 使用 localStorage 中保存的标签页
      this.activeName = savedTab;
    }
  },
  watch: {
    // 监听 activeName 变化并保存到 localStorage
    activeName(newName) {
      localStorage.setItem('activeTab', newName);
    },
  },
};
</script>

<style scoped>
.el-tabs__header {
  margin: 0 auto;
  margin: 0 0 15px;
  padding: 0;
  position: relative;
  width: 80%;
}

:deep(.el-tabs__nav-scroll) {
  display: flex;
  justify-content: center;
}

/* 去掉长分割线 */
:deep(.el-tabs__nav-wrap::after) {
  position: static !important;
}

/* 去掉下划线 */
:deep(.el-tabs__active-bar) {
  background-color: transparent !important;
}

/* 未选中状态的样式 */
:deep(.el-tabs__item) {
  color: #000;
  padding-right: unset !important;
  padding-left: unset !important;
  border-radius: 30px;
  border: 1px solid #000;
  margin-right: 2rem;
  width: 135px;
}

/* 选中状态的样式 */
:deep(.el-tabs__item.is-active) {
  border: 1px solid #000;
  border-radius: 30px;
  width: 160px;
  background: #000;
  color: #fff;
  font-size: 21px;
  height: 4rem;
  font-weight: bold;
}

/* 选中状态的样式 */
:deep(.el-tabs__item.is-active:hover) {
  color: #fff;
}

::v-deep .el-tabs__item {
  padding: 0;
  border: 1px solid #000;
  border-radius: 30px;
  width: 160px;
  color: #000;
  font-size: 21px;
  height: 4rem;
  font-weight: bold;
}

:deep(.el-tabs__item:hover) {
  color: black;
}

@media screen and (max-width: 768px) {
  ::v-deep .el-tabs__item {
    border: 1px solid #000;
    border-radius: 16px;
    width: 80px;
    font-size: 14px;
    height: 3rem;
    font-weight: bold;
  }

  :deep(.el-tabs__item.is-active) {
    border-radius: 16px;
    width: 80px;
    font-size: 14px;
    height: 3rem;
  }

  :deep(.el-tabs__nav) {
    left: 15px;
  }
}
</style>
