<template>
    <div class="getSearch-container">

        <!-- 搜索模块 -->
        <div class="getSearch-box">
            <el-row :gutter="20" style="height:4rem">
                <el-col :span="24">
                    <ul class="getSearch-ul">
                        <li class="getSearch-selected">
                            <el-select v-model="selectedChain" placeholder="请选择链">
                                <el-option v-for="chain in chains" :key="chain.value" :label="chain.label" :value="chain.label">
                                    <template v-slot:default>
                                        <img :src="chain.img" alt="" class="chain-icon" />
                                        <span>{{ chain.label }}</span>
                                    </template>
                                </el-option>
                                <template v-slot:prefix>
                                    <img :src="selectedChainImg" alt="" class="chain-icon" v-if="selectedChainImg"/>
                                </template>
                            </el-select>
                        </li>
                        <li class="getSearch-input">
                            <el-input v-model="searchKey" placeholder="输入或选择地址开始搜索" style="width:100%;"></el-input>
                        </li>
                        <li class="getSearch-button">
                            <el-button :disabled="isDisabled" size="large"  @click="searchDataHas" ><el-icon><Search /></el-icon><span class="getSerach-button-span">开始分析</span></el-button>
                            
                        </li>
                    </ul>

                </el-col>
            </el-row>
        </div>
        <el-divider></el-divider>

        <div class="getCases-list">
            <div class="clear-getcases">
                <div class="cases-top-box">
                    <span class="cases-title">典型案例：</span>
                    <el-tooltip v-for="(item, index) in cases" :key="index" class="item" effect="dark" :content="item.content" placement="top-start">
    
                        <el-button :class="{ active: isActive(item.caseName, item.chainShortName, item.address) }" class="cases-button" @click="showData(item.caseName, item.chainShortName, item.address)">{{ item.caseName }}</el-button>
                    </el-tooltip>
                </div>
            </div>
        </div>

        <!-- 2.风险评分和地址概述 -->
         <div v-if="getRiskList">
             <div class="getSearch-text-one" v-loading="fullscreenLoading">
                 <el-row>
                     <el-col class="detail-top-box-title" :span="2">
                         <img :src="getSelectionImage" alt="" class="chains-icon" v-if="getSelectionImage"/>
                     </el-col>
                     <el-col :span="22">
                         <el-text class="chains-name-text">{{selectedChain}}</el-text>|
                         <el-text class="chains-address-text">交易哈希</el-text>
                         <div class="chains-address-keys-box">
                             <p class="chains-address-keys">
                                 {{ searchKey }}
                             </p>
                             <el-tooltip class="item" effect="dark" content="复制地址" placement="top">
                                 <el-icon class="chains-address-keys-item" @click="copyAddress(searchKey)">
                                     <copy-document />
                                 </el-icon>
                             </el-tooltip>
                         </div>
                     </el-col>
                 </el-row>
     
     
                 <el-row>
                     <el-col :span="24">
                         <h1>AML 风险评分</h1>
                         <el-row type="flex" justify="center">
                             <el-col :span="12" >
                                 <div class="risk-box-list-ul">
                                     <ul>
                                         <li class="risk-box-title">风险评分：</li>
                                         <li :class="riskTextClass" class="red-risk-text-one">{{ translatedRiskLevel }}</li>
                                         <li :class="riskClass" class="risk-text-two-p">{{ riskScore }}</li>
                                     </ul>
                                 </div>
                                 <div class="risk-box-bottom-ul">
                                     <ul>
                                         <li class="risk-box-title">风险标签：</li>
                                         <li class="risk-bottom-text">{{ getRiskMessage }}</li>
                                     </ul>
                                 </div>
                                 <div class="risk-box-bottom-data">
                                     <el-table
                                     :data="getRiskDetails"
                                     >
                                         <el-table-column prop="type" label="风险类型">
                                         </el-table-column>
                                         <el-table-column label="钱包地址/风险标签">
                                             <template #default="{ row }">
     
                                                 <span>{{ shortenAddress(row.address) }} ({{ row.label }})</span>
     
                                                 <el-tooltip class="item" effect="dark" content="复制地址" placement="top">
                                                     <el-icon @click="copyAddress(row.address)">
                                                         <copy-document />
                                                     </el-icon>
                                                 </el-tooltip>
                                                 <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
                                                     <el-icon @click="openInNewWindow(selectedChain,row.address)">
                                                         <Link />
                                                     </el-icon>
                                                 </el-tooltip>
                                             </template>
                                         </el-table-column>
                                         <el-table-column
                                             label="转账数额(USD)/百分比">
                                             <template #default="{ row }">
                                                 {{ row.volume }}/{{ row.percent }}%
                                             </template>
                                         </el-table-column>
                                     </el-table>
                                 </div>
                             </el-col>
                             <el-col :span="12">
                                 <div id="maintwo"></div>
                             </el-col>
                         </el-row>
                     </el-col>
                 </el-row>
     
             </div>
         </div>
    </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
// import { ElMessageBox } from 'element-plus';
export default {
    props: {
      category: {
          type: String,
          required: true,
      },
      address: {
          type: String,
          required: true,
      },
    },
    data() {
        return {
            selectedChain: '', // 当前选中的链
            searchKey: '', // 搜索框的输入值

            currentCase: '',
            currentChain: '',
            currentAddress: '',

            chains: [
                { value: 'btc', label: 'BTC', img: require('../assets/btc.png') },
                { value: 'eth', label: 'ETH', img: require('../assets/eth.png') },
                { value: 'tron', label: 'USDT-TRC20', img: require('../assets/trx.png') },
                { value: 'ethereum', label: 'USDT-ERC20', img: require('../assets/erc.png') }
            ],
            apiKey: 'H2ncD7G6RBYUEPbkFXhgulmN3WOazAxQ', 
            getChainsList:'',
            getRiskList:'',
            getRiskMessage:'',//风险标签
            getRiskDetails:[],
            getRiskDetails_score:null,
            dialogVisible: false,
            typeMap: {
                suspected_malicious: '疑似恶意',
                high_risk: '高风险',
                malicious: '恶意',
                medium_risk: '中等风险'
            },
            riskScore: 0,
            translatedRiskLevel: '',
            riskClass: '', // 用于风险的背景色
            riskTextClass: '', // 用于风险文字颜色
            lockScroll:true,
            fullscreenLoading: false,
            getSelectionImage:'',
            blockchainType: '', // 当前区块链类型
            addressTypeLabel: '', // 地址类型标签

            cases: [
                { caseName: 'Plus Token 传销事件', chainShortName: 'BTC', address: 'a893e394120135be6431254bc5b7184b33ec1fe8eb38e88a4ba05e30f04e3966', content: 'a893e394120135be6431254bc5b7184b33ec1fe8eb38e88a4ba05e30f04e3966' },
                { caseName: 'Github 用户 1,400 枚 BTC 盗币事件', chainShortName: 'BTC', address: 'ef600c380a239d9b929c6c964deaf7060e309750950a516cee65576232b0c53c', content: 'ef600c380a239d9b929c6c964deaf7060e309750950a516cee65576232b0c53c' },
                { caseName: 'Blockchain.info 私钥漏洞事件', chainShortName: 'BTC', address: '85da64eda91b031e7a628cd145bfe6a6ece731b2bacffceb8c76c592a80ee7e0', content: '85da64eda91b031e7a628cd145bfe6a6ece731b2bacffceb8c76c592a80ee7e0' },
                { caseName: 'Binance 黑客事件', chainShortName: 'BTC', address: 'e8b406091959700dbffcff30a60b190133721e5c39e89bb5fe23c5a554ab05ea', content: 'e8b406091959700dbffcff30a60b190133721e5c39e89bb5fe23c5a554ab05ea' },
            ],
        };
    },
    computed: {
        selectedChainImg() {
            const selected = this.chains.find(chain => chain.label === this.selectedChain);
            return selected ? selected.img : '';
        },
        isDisabled() {
            // 当没有选择链或没有输入密钥时，返回 true 使按钮禁用
            return !this.selectedChain || !this.searchKey;
        },
    },
    watch: {
        currentAddress(newAddress, oldAddress) {
            if (newAddress !== oldAddress) {
                this.searchKey = newAddress
                this.searchDataHas()
            }
        },
        currentChain(newChainShortName,oldChainShortName) {
            if (newChainShortName !== oldChainShortName) {
                this.coin = newChainShortName
            }
        }
    },
    mounted() {
        this.showData(this.cases[0].caseName, this.cases[0].chainShortName, this.cases[0].address);
    },
    methods: {
        showData(caseName, chainShortName, address) {
            this.currentCase = caseName;
            this.currentChain = chainShortName;
            this.currentAddress = address;
            this.searchKey = address; // 回显搜索框内容
            this.selectedChain = chainShortName; // 回显下拉框内容
        },

        isActive(caseName, chainShortName, address) {
            return this.currentCase === caseName && this.currentChain === chainShortName && this.currentAddress === address;
        },

        checkHash(hash, network) {
            switch (network) {
                case 'TRX':
                case 'USDT-TRC20':
                    return /^[a-fA-F0-9]{64}$/.test(hash); // TRON transaction hash format
                case 'ETH':
                case 'ERC20':
                    return /^0x[a-fA-F0-9]{64}$/.test(hash); // ETH/ERC20 transaction hash format
                case 'BTC':
                    return /^[a-fA-F0-9]{64}$/.test(hash); // BTC transaction hash format
                default:
                    return false;
            }
        },

        searchDataHas() {

            if (!this.checkHash(this.searchKey, this.selectedChain)) {
                this.$message({
                    message: '未找到结果，请检查所选币种或地址格式是否正确',
                    type: 'warning'
                });
                return;
            }
            
            this.riskData()
        },


        // 复制地址的函数
        copyAddress(address) {
            navigator.clipboard
                .writeText(address)
                .then(() => {
                    this.$message({
                        message: '地址复制成功!',
                        type: 'success',
                    });
                })
        },


        openInNewWindow(category, address) {
            const url = `/address/${category}/${address}`;
            this.currentAddress = address
            window.open(url, '_blank');
        },


        // get风险交易分析接口
        async riskData() {
    if (!this.selectedChain || !this.searchKey) {
        console.error("请选择链并输入搜索密钥。");
        return;
    }

    let coin = this.selectedChain;
    let txid = this.searchKey;
    let api_key = 't5Uf7L10jEkXpOxWZ6Bzea4YGuMFcqHN';

    const getJyList = `https://openapi.misttrack.io/v1/risk_score?coin=${coin}&txid=${txid}&api_key=${api_key}`;

    this.fullscreenLoading = true;
    try {
        const response = await axios.get(getJyList, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });

        this.getRiskList = response.data.data || {}; // 初始化 getRiskList


        // 确保 getRiskList 有 score 属性
        const score = this.getRiskList.score || 0;
        this.getRiskDetails_score = score;

        let getselectedChain = this.selectedChain;
        if (getselectedChain == 'ETH') {
            this.getSelectionImage = require('../assets/eth.png');
        } else if (getselectedChain == 'USDT-TRC20') {
            this.getSelectionImage = require('../assets/trx.png');
        } else if (getselectedChain == 'USDT-ERC20') {
            this.getSelectionImage = require('../assets/erc.png');
        } else {
            this.getSelectionImage = require('../assets/btc.png');
        }

        // 翻译风险等级为中文
        const riskLevelMap = {
            Low: '低',
            Moderate: '适中',
            High: '高',
            Severe: '严重'
        };
        this.translatedRiskLevel = riskLevelMap[this.getRiskList.risk_level] || '未知';

        // 设置风险分数和样式类
        switch (this.getRiskList.risk_level) {
            case 'Low':
                this.riskClass = 'green-risk'; 
                this.riskTextClass = 'green-risk-text'; 
                break;
            case 'Moderate':
                this.riskClass = 'yellow-risk'; 
                this.riskTextClass = 'yellow-risk-text'; 
                break;
            case 'High':
            case 'Severe':
                this.riskClass = 'red-risk'; 
                this.riskTextClass = 'red-risk-text'; 
                break;
            default:
                this.riskClass = '';
                this.riskTextClass = '';
                break;
        }
        this.riskScore = this.getRiskList.score || 0;

        // 获取风险交易详情并转换type为中文
        this.getRiskDetails = this.getRiskList.risk_detail?.map(item => ({
            ...item,
            type: this.typeMap[item.type] || item.type, // 处理type字段
        })) || [];

        let detail_list = this.getRiskList.detail_list || [];

        const riskMessagesMap = {
            "Malicious Address": "恶意地址",
            "Suspected Malicious Address": "疑似恶意地址",
            "High-risk Tag Address": "高风险标签地址",
            "Medium-risk Tag Address": "中等风险标签地址",
            "Mixer": "混合器",
            "Sanctioned Entity": "受制裁实体",
            "Risk Exchange": "风险交换",
            "Gambling": "赌博",
            "Involved Theft Activity": "涉及盗窃活动",
            "Involved Ransom Activity": "涉及赎金活动",
            "Involved Phishing Activity": "涉及网络钓鱼活动",
            "Interact With Malicious Address": "与恶意地址交互",
            "Interact With Suspected Malicious Address": "与可疑恶意地址交互",
            "Interact With High-risk Tag Address": "与高风险标签交互",
            "Interact With Medium-risk Tag Addresses": "与中等风险标签交互"
        };

        let risk_messages = [];
        for (let detail of detail_list) {
            risk_messages.push(riskMessagesMap[detail] || '未知风险');
        }
        this.getRiskMessage = risk_messages.join('、 ');

        // 在数据处理完成后初始化图表
        this.$nextTick(() => {
            this.initChart();
            this.fullscreenLoading = false; // 确保在图表渲染后才关闭 loading
        });

    } catch (error) {
        this.fullscreenLoading = true;
        this.$message({
            message: '请求多次，请稍后重试',
            type: 'warning'
        });
    }
},

            
        // 简写地址的函数
        shortenAddress(address) {
            return `${address.slice(0, 6)}...${address.slice(-4)}`;
        },

        openDialog() {
            this.dialogVisible = true;
        },

        submitForm() {
            this.dialogVisible = false;
        },

        handleClose() {
            // Handle any additional logic when the dialog is closed
        },

        // 图标
        initChart() {
            const chartDom = document.getElementById('maintwo');
            if (!chartDom) {
                console.warn('Chart DOM element not found');
                return;
            }

            const myChart = echarts.init(chartDom);

            // 获取 risk_level 和 hacking_event
            const { risk_level: riskLevel = '', hacking_event: hackingEvent = false, score = 0 } = this.getRiskList;

            // 确定雷达图的值和颜色
            let radarValue = [30, 30, 30]; // 默认值
            let fillColor = '#4caf50'; // 默认绿色

            // 根据 hackingEvent 和 riskLevel 调整值和颜色
            if (hackingEvent) {
                radarValue = [30, score, 30]; // 黑客事件使用 score
                fillColor = '#f44336'; // 红色
            } else {
                if (riskLevel === 'Low') {
                fillColor = '#4caf50'; // 绿色
                } else if (riskLevel === 'Severe') {
                radarValue = [100, 100, 100]; // 全部设置为高值
                fillColor = '#f44336'; // 红色
                } else {
                fillColor = '#ff9800'; // 橙色
                }
            }

            const option = {
                title: {
                text: ''
                },
                legend: {
                // data: ['风险分析'] // 可选，移除如果不需要
                },
                radar: {
                shape: 'polygon',
                indicator: [
                    { name: '风险实体', max: 100 },
                    { name: '黑客事件', max: 100 },
                    { name: '可疑交易', max: 100 }
                ],
                splitNumber: 5, // 根据需求调整分割数量
                splitLine: {
                    lineStyle: {
                    color: '#ddd'
                    }
                },
                splitArea: {
                    areaStyle: {
                    color: ['#f5f5f5', '#fff']
                    }
                },
                axisLine: {
                    lineStyle: {
                    color: '#ccc'
                    }
                },
                name: {
                    textStyle: {
                    color: '#333',
                    fontSize: 14
                    }
                }
                },
                series: [
                {
                    name: '风险分析',
                    type: 'radar',
                    data: [
                    {
                        value: radarValue,
                        // name: '风险评估' // 可选，移除如果不需要
                    }
                    ],
                    itemStyle: {
                    color: fillColor,
                    borderColor: hackingEvent ? '#d32f2f' : '#388e3c',
                    borderWidth: 2
                    },
                    areaStyle: {
                    opacity: 0.2
                    }
                }
                ]
            };

            myChart.setOption(option);
        }

    }
};
</script>

<style scoped>
.el-row {
    text-align: left;
}

.getSearch-box {
    height: 3rem;
}

.getSearch-ul {
    overflow: hidden;
    padding: 0;
    margin: 0;
}

/* .el-icon svg {
    height: 1px;
    width: 1px;
} */

.getSearch-ul li {
    float:left;
    list-style:none;
}

.getSearch-selected {
    width: 20%;
}

.getSearch-input {
    width: 65%;
}

.getSearch-button {
    width: 15%;
}

.getSearch-button .el-button:disabled {
  background-color: #d3d3d3 !important; /* 禁用状态的背景色 */
  border-color: #d3d3d3 !important; /* 禁用状态的边框色 */
  color: #fff !important; /* 禁用状态的文字颜色 */
}

.getSearch-detail-top-box {
    padding: 31px;
    border-radius: 30px;
    /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    margin: 30px;
}

.detail-top-box-title {
    padding-left: 10px;
}

.chains-name-text {
    font-size: 19px;
    font-weight: bold;
    margin-right: 10px;
}

.chains-address-text {
    font-size:15px;
    color: #888;
}

.chains-icon {
    width: 50px;
    height: 50px;
    margin-right: 5px;
}


.getSearch-text-one {
    padding: 31px;
    border-radius: 30px;
    /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    margin: 30px;
}

.custom-popover .popover-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust height as needed */
}


/* 使用 /deep/ 伪类选择器 */
::v-deep .el-select__wrapper {
  height: 3.15rem; /* 设置最大高度 */
  /* box-shadow: none; */
  font-size: 18px;
}

::v-deep .el-select__wrapper:hover {
    /* box-shadow: none; */
}

.el-select-dropdown__item {
    justify-content: start;
    height: 61px;
    line-height: 70px;
    font-size: 18px;
    display: flex;
    align-items: center;
}

::v-deep .el-input__wrapper {
    height: 3rem;
    font-size: 18px;
    /* box-shadow: none; */
}

::v-deep .el-button--large {
    --el-button-size: 50px;
    width: 100%;
    background: #000;
    color: #fff;
    font-size: 18px;
}

.item {
    margin-right: 10px;
}

.active {
    background-color: #000 !important; /* 激活时的背景色 */
    color: white !important; /* 激活时的文字颜色 */
}

.search-section {
    margin-bottom: 1rem;
}

.chain-icon {
    width: 40px;
    height: 40px;
    margin-right: 5px;
}

.bottom-risk-box {
    width: 100%;
    /* text-align: center; */
    color: #fff;
    overflow: hidden;
    /* height: 100px; */
}

.risk-box-list-ul {
    overflow: hidden;
    width: 100%;
    margin:0 auto;
    /* text-align: center; */
}

.risk-box-bottom-ul {
    overflow: hidden;
    width: 100%;
    margin:0 auto;
    /* text-align: center; */
}


.risk-box-list-ul ul li{
    float:left;
    list-style-type: none;
    /* width:25%; */
}

.risk-box-list-ul ul{
    padding: 0;
    height: 30px;
}

.risk-box-bottom-ul ul li{
    float:left;
    list-style-type: none;
    /* width:25%; */
}

.risk-box-bottom-ul ul{
    padding: 0;
    height: 30px;
    line-height: 30px;
}

.risk-box-title {
    height: 30px;
    line-height:30px;
    font-weight: bold;
}

.risk-bottom-text {
    font-style: oblique;
}

.green-risk {
    background-color: #4caf50; /* 绿色背景 */
}

.yellow-risk {
    background-color: #ff9800; /* 黄色背景 */
}

.red-risk {
    background-color: #f44336; /* 红色背景 */
}

.green-risk-text {
    color: #4caf50; /* 文字色为绿色 */
}

.yellow-risk-text {
    color: #ff9800; /* 文字色为黄色 */
}

.red-risk-text {
    color: #f44336; /* 文字色为红色 */
}

.risk-text-title {
    color: #000;
    width: 20%;
    margin: 0;
    font-weight: bold;
}

.red-risk-text-one {
    font-size: 18px;
    border: 1px solid #e7e7e7;
    margin: 0;
    width: 25%;
    height: 29px;
    text-align: center;
}

.risk-text-two-p {
    width: 25%;
    color: #fff;
    font-size: 19px;
    margin: 0;
    height: 30px;
    text-align: center;
    line-height: 30px;
}


.getSearch-detail-box {
    background: rgb(238, 238, 238);
    border-radius: 25px;
    width: 90%;
    margin: 0 auto;
}

.detail-box-right {
    text-align: right;
}

.detail-button-box {
    padding: 0;
    margin-right: 20px;
    margin-top: 4px;
    color: #999;
}

.el-button:hover {
    background: #444;
    color: #fff;
    border:1px solid #000;
}

.getSearch-list-box {
    padding: 31px;
    border-radius: 30px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    margin: 30px;
}

.grid-title {
    font-size: 17px;
    font-weight: bold;
}

.grid-text {
    font-size: 14px;
    color: gray;
}

.el-pagination {
    justify-content: center;
}

.getCases-list {
    height: 2rem;
    width: 100%;
}

.clear-getcases {
    overflow: hidden;
}

.cases-top-box {
    width: 100%;
    float:left;
    text-align: left;
}

.cases-button {
    background: #f9f9f9;
    color: #000;
}

.cases-title {
    font-weight: bold;
}

.cases-title-bottom {
    font-weight: bold;
    margin-left:1rem;
}

#maintwo {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    height: 500px;
}

.chains-address-keys-box {
    overflow: hidden;
    width:100%;
}

.chains-address-keys {
    font-size: 15px;
    float:left;
}

.chains-address-keys-item {
    float: left;
    margin-top: 0.9em;
    margin-left: 0.2em;

}

@media screen and (max-width:768px){ 
    .getSearch-ul {
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    ::v-deep .el-select__wrapper {

        font-size: 0;
        gap: 0;
    }
    .getSearch-input {
        width: 65%;
    }

    .el-col-12 {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
    }

    .el-col-1 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .getSearch-detail-box[data-v-6883d1be] {
        width: 100%;
        margin: 0 auto;
    }
    .chains-address-keys {
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 80%;
    }

    .getSearch-text-one{
        padding: 15px;
        border-radius: 30px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        margin: 2px;
    }

    .getSearch-list-box {
        padding: 7px;
        border-radius: 30px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        margin: 2px;
        margin-top: 2rem;
    }

    #maintwo {
        position: relative;
        right: 15px;
        width: 100%;
        height: 300px;
        margin-top: 40px;
    }

    ::v-deep .el-select__wrapper:hover {
        /* box-shadow: none; */
    }

    .el-select-dropdown__item {
        justify-content: start;
        height: 61px;
        line-height: 70px;
        font-size: 12px;
        display: flex;
        align-items: center;
    }

    ::v-deep .el-input__wrapper {
        height: 3rem;
        font-size: 12px;
    }

    ::v-deep .el-button--large {
        --el-button-size: 43px;
        /* padding: 12px 100px; */
        padding: 24px 0;
        width: 100%;
    }

    .chain-icon {
        width: 30px;
        height: 30px;
        margin-right: 0;
    }

    .grid-title {
        font-size: 17px;
        font-weight: bold;
    }

    .grid-text {
        font-size: 14px;
        color: gray;
    }

    .detail-in-text {
        margin-right: 10px;
    }

    ::v-deep .el-dialog {
        --el-dialog-width: 95%;
    }

    .getSerach-button-span {
        display: none;
    }

    .el-button.is-round {
        border-radius: 0;
    }

    .el-button--large.is-round {
        padding: 24px 26px;
    }

    .el-select__placeholder {
        display: none;
    }

    .getCases-list {
        height: 10rem;
    }

    .cases-top-box {
        width: 100%;
    }

    .cases-bottom-box {
        width: 100%;
    }

    .cases-button {
        margin-top: 8px;
        font-size: 12px;
    }

    .cases-title {
        font-size: 12px;
    }

    .cases-title-bottom {
        font-size: 12px;
        margin-left: 0;
    }
    
    .el-col-2 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

</style>
