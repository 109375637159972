<template>
    <div class="page_container">
        <el-row>
            <!-- 左侧图片区域 -->
            <el-col :span="12">
                <h1 class="product_ld">产品亮点</h1>
            </el-col>
            <!-- 右侧图标+文字区域 -->
            <el-col :span="12" class="right-col">
                <div v-for="(item, index) in items" :key="index" class="item-container">
                    <div class="item-content">
                    <el-icon class="custom-icon">
                        <component :is="item.iconComponent" />
                    </el-icon>
                    <div class="text-container">
                        {{ item.text }}
                    </div>
                    </div>
                    <div class="item-divider"></div> <!-- 使用 CSS border-bottom 代替 el-divider -->
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { text: '一键式地址挖掘', iconComponent: 'Aim' },
        { text: '链上工具集', iconComponent: 'Lock' },
        { text: '调证协查一体化', iconComponent: 'View' },
      ],
    };
  },
};
</script>


<style scoped>
.page_container {
    margin-top:5rem;
}

.product_ld {
  font-size: 4rem;
}

.left-image {
  width: 65%;
  height: auto;
  border-radius: 8px; /* 圆角效果 */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 阴影效果 */
}

.right-col {
  display: flex;
  flex-direction: column;

}

.item-container {
  margin-bottom: 115px; /* 增加间隔 */
}

.item-content {
  display: flex;
  align-items: center;
}

.custom-icon {
  font-size: 40px; /* 自定义图标大小 */
}

.text-container {
  font-size: 22px;
  color: #333;
  margin-left: 20px; /* 增加文字与图标之间的间距 */
  line-height: 1.5; /* 调整文字行高 */
}

.item-divider {
  width: 100%;
  border-bottom: 1px solid #ccc; /* 使用 border-bottom 创建分割线 */
  margin-top: 10px;
}


@media screen and (max-width:768px){ 
  .item-container {
      margin-bottom: 2em;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 35px;
  }
  .text-container[data-v-2ac44e53] {
      font-size: 15px;
      color: #333;
      margin-left: 20px;
      line-height: 1.5;
  }
  .el-icon svg {
      height: 30px;
      width: 30px;
  }
  .page_container {
      margin-top: 0;
  }
  .product_ld {
    font-size: 2rem;
  }
}

</style>