<template>
  <div class="page-container">
    <div class="footer_box">
      <el-row type="flex" justify="center">
        <el-col :span="8" class="footer-item">
          <img class="left-image" src="../assets/logo.png" alt="Left Image" style="width: 50%;" />
        </el-col>
        <el-col :span="8" class="footer-item">
            <h2>联系我们</h2>
            <p>公司名称：深圳市华创技术有限公司</p>
            <p>电话：0755-88805533</p>
            <p>手机：152-2022-2255</p>
            <p>邮箱：hwachong@hwachong.com.cn</p>
            <p>地址：深圳市福田区莲花街道康欣社区北环大道7043号青海大厦28B</p>
        </el-col>
        <el-col :span="8" class="footer-item">
          <img class="left-image" src="../assets/ercode.jpeg" alt="Left Image" style="width: 20%;" />
          <p>关注我们</p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="copyright">
            版权所有© 深圳市华创技术有限公司  粤ICP备2023108494号-1  粤公网安备44030002001089号
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { text: '一键式地址挖掘', iconComponent: 'Aim' },
        { text: '链上工具集', iconComponent: 'Lock' },
        { text: '调证协查一体化', iconComponent: 'View' },
        { text: '24 小时地址监控与预警', iconComponent: 'BellFilled' },
        { text: 'DeFi 合约风险信息', iconComponent: 'Cpu' },
      ],
    };
  },
};
</script>

<style scoped>
.copyright {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #999;
}
.el-row.is-justify-center {
  justify-content: center;
  align-items: center;
}



.footer_box {
  background-color: #000;
    color: #fff;
    padding: 30px 0;
    margin-top: 50px;
}

@media screen and (max-width:768px){ 
  .el-col-8 {
    flex: 0 0 85%;
    max-width: 85%;
  }


}

</style>

